/*
    6/13/2020 - Suhas
    
    This is the header of the Landing page
    It consist of the immediate call number, Logo and the Social Media Links
*/

import React, { useState } from "react";
import { Navbar, Nav, Row, Col } from "react-bootstrap";
import { GrFacebookOption, GrLinkedinOption, GrTwitter } from "react-icons/gr";
import { IoMdCall } from "react-icons/io";
// import Geocode from "react-geocode";
import '../css/header.css';
import logo from "../img/LowRates.TV.png";
import twitterlogo from "../img/twitterlogo.png";
import UserWayAccessibilityMenu from "../components/UserWayAccessibilityMenu/index";

function Header() {

    const [navbar, setNavbar] = useState(false);

    const changebackground = () => {
        if (window.scrollY >= 9) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    window.addEventListener('scroll', changebackground);

    return (
        <>

            <Navbar fixed="top" bg="white" expand="md" variant="light" className="justify-content-center py-1 {navbar ? 'navbar active' : 'navbar' }" >
                <Navbar.Brand className="w-100 py-0">
                    <Nav.Item>
                        <span className="contactHead">Have questions? Get Answers. </span><IoMdCall /><a href="tel:+18554877842">1-(855) ITS-QUICK</a>
                    </Nav.Item>
                </Navbar.Brand>
                <Navbar.Brand>
                    <img alt="LowRatesTv Logo" className="d-inline-block align-top ps-0" src={logo} data-toggle="collapse"
                    />{' '}
                </Navbar.Brand>
                <UserWayAccessibilityMenu color="white" width={30} height={30} />
                <Navbar.Brand className="w-100">
                    <Nav className="w-100 justify-content-center">
                        <Row>
                            <Col className="socialHead">
                                <a title="Like us on Facebook!" style={{ margin: "0 5px" }} href="https://www.facebook.com/SunWestMortgage" target="_blank" rel="noopener noreferrer"><GrFacebookOption style={{ fontSize: "x-large" }} /></a>&nbsp;
                                <a title="Follow us on LinkedIn!" style={{ margin: "0 5px" }} href="https://www.linkedin.com/company/sun-west-mortgage-company/" target="_blank" rel="noopener noreferrer"><GrLinkedinOption style={{ fontSize: "x-large" }} /></a>&nbsp;
                                <a title="Follow us on Twitter!" style={{ margin: "0 5px" }} href="https://twitter.com/sunwestmortgage?lang=en" target="_blank" rel="noopener noreferrer"><img
                                    style={{
                                        height: '20px',
                                        width: '20px',
                                        fontSize: 'x-large'
                                    }}
                                    src={twitterlogo}
                                    alt="twitterlogo"
                                /></a>&nbsp;
                            </Col>
                        </Row>
                    </Nav>
                </Navbar.Brand>
            </Navbar>

        </>
    )
}

export default Header;