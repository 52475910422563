import React from "react";
import Modal from "react-bootstrap/Modal";

function TexasModal() {
    return(
        <React.Fragment>
            <Modal.Header closeButton>
                <Modal.Title><p style={{fontSize:"1rem",color:"#283859"}}><strong>Texas Complaint Notice:</strong></p>
                    <p style={{fontSize:"1rem",lineHeight:"25px"}}>Figure: 7 TAC &#167;81.200(c)</p></Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-justify">
                <div>
                    <p className="text-center"><u><strong>TEXAS MORTGAGE BANKER DISCLOSURE</strong></u></p>
                    &quot;CONSUMERS WISHING TO FILE A COMPLAINT AGAINST A MORTGAGE BANKER OR A LICENSED MORTGAGE BANKER RESIDENTIAL MORTGAGE LOAN ORIGINATOR SHOULD COMPLETE AND SEND A COMPLAINT FORM TO THE TEXAS DEPARTMENT OF SAVINGS AND MORTGAGE LENDING, 2601 NORTH LAMAR, SUITE 201, AUSTIN, TEXAS 78705. COMPLAINT FORMS AND INSTRUCTIONS MAY BE OBTAINED FROM THE DEPARTMENT&#39;S WEBSITE AT <a href="http://www.sml.texas.gov/" title="Texas Department of Savings and Mortgage Lending" rel="noopener noreferrer">WWW.SML.TEXAS.GOV</a>. A TOLL-FREE CONSUMER HOTLINE IS AVAILABLE AT <a href="tel:+18772765550">1-877-276-5550.</a><br/><br/>
                    THE DEPARTMENT MAINTAINS A RECOVERY FUND TO MAKE PAYMENTS OF CERTAIN ACTUAL OUT OF POCKET DAMAGES SUSTAINED BY BORROWERS CAUSED BY ACTS OF LICENSED MORTGAGE BANKER RESIDENTIAL MORTGAGE LOAN ORIGINATORS. A WRITTEN APPLICATION FOR REIMBURSEMENT FROM THE RECOVERY FUND MUST BE FILED WITH AND INVESTIGATED BY THE DEPARTMENT PRIOR TO THE PAYMENT OF A CLAIM. FOR MORE INFORMATION ABOUT THE RECOVERY FUND, PLEASE CONSULT THE DEPARTMENT&#39;S WEBSITE AT <a href="http://www.sml.texas.gov/" title="Texas Department of Savings and Mortgage Lending">WWW.SML.TEXAS.GOV</a>.&quot;<br/><br/>
                    THIS DISCLOSURE NOTICE ONLY APPLIES TO RESIDENTIAL MORTGAGE LOANS ON REAL ESTATE LOCATED IN TEXAS.
                    <br/><br/><br/>
                    <span style={{fontSize:"15px",color:"#283859"}}><strong>Texas&nbsp;Servicing&nbsp;Disclosure:</strong></span><br/><br/>
                    The following disclosure notice only applies to residential mortgage loans on real estate located in Texas:<br/><br/>
                    <i>COMPLAINTS REGARDING THE SERVICING OF YOUR MORTGAGE SHOULD BE SENT TO THE DEPARTMENT OF SAVINGS AND MORTGAGE LENDING, 2601 NORTH LAMAR, SUITE 201, AUSTIN, TX 78705. A TOLL-FREE CONSUMER HOTLINE IS AVAILABLE AT <a href="tel:+18772765550">877-276-5550.</a></i><br/><br/>
                    A complaint form and instructions may be downloaded and printed from the Department&#39;s website located at <a href="http://www.sml.texas.gov/" title="Texas Department of Savings and Mortgage Lending" rel="noopener noreferrer">www.sml.texas.gov</a> or obtained from the department upon request by mail at the address above, by telephone at its toll-free consumer hotline listed above, or by email at <a href="mailto:smlinfo@sml.texas.gov">smlinfo@sml.texas.gov</a>.
                </div>
            </Modal.Body>
        </React.Fragment>
    );
}

export default TexasModal;