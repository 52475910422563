import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "../css/testimonials.css";

// Import images
import review from './images/review-box.svg';
import rating from './images/5 star icon_4.9.png';

function Testimonials() {
    return(
        <React.Fragment>
            <Container fluid className="pb-2 pb-md-5 multiImageBack">
                <Row>
                    <Col lg={12} className="d-flex justify-content-center align-items-center text-center py-3">
                        <h3 style={{verticalAlign: "middle"}}><Image fluid src={rating} alt="rating stars" style={{width:"50%"}} /> 1800+&nbsp;reviews</h3>
                    </Col>
                </Row>
                <Row className="w-100 testimonials-row justify-content-center">
                    <Col lg={4} className="text-center widgetWrapper" style={{backgroundImage:`url(${review})`,backgroundSize: "contain",backgroundColor:"transparent",backgroundRepeat:"no-repeat",backgroundPosition:"center"}}>
                    <script type="text/javascript" src="https://birdeye.com/embed/v4/154827373198858/8/1234567985183"></script>

                        <div id="bf-revz-widget-1234567985183" style={{WebkitOverflowScrolling: 'touch'}} className="responsive-iframe">
                            <iframe className="" name="bfiframe" src="https://birdeye.com/widget/render.php?bid=154827373198858&wid=8&ver=4&update=0" id="bfpublish" width={270} height={170} scrolling="no" frameBorder={0} title="Customer reviews" style={{borderWidth: '0px', display: 'block', margin: '0px auto', overflow: 'hidden'}} />
                            
                            <div className="bf-dv d-none"><span className="bf-spn"> powered by <a className="bf-pwr" href="https://birdeye.com?utm_source=SRC&utm_medium=widget_review-feed&utm_campaign=birdeye_widget&utm_term=powered-by-birdeye&utm_content=rotating-widget_#51a8d3" rel="noopener noreferrer" target="_blank">BirdEye</a></span></div></div>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default Testimonials;