import React, { useState, useEffect, useCallback } from "react";
import "./App.css";
import Container from "react-bootstrap/Container";
import axios from 'axios';

// Import pages
import Header from "./modules/Header";
import Footer from "./modules/Footer";
import { Rates } from "rate-quotes";
import { BorrowerLeadGenerationTool } from "borrower-lead-generation-tool";

function App() {
  // stores current formRef
  const [node, setNode] = useState(null);
  // tracks changes to formRef and stores current with setNode
  const formRef = useCallback((node) => {
    setNode(node);
  }, []);

  useEffect(()=>{
    axios
      .post(
        process.env.REACT_APP_SUNSOFT_RESTSERVER_STATE_LIST,
        { domain: "swmc.com", companyCode: "SUNWST000" },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .then((response) => {
        let validStates = response.data;
        if (validStates) {
          // set localStorage for userSession in RateQuotes for state error checking.
          localStorage.setItem("stateList", JSON.stringify(validStates));
        }
      })
      .catch((error) => {
        console.error('STATE_LIST Error: ', error);
      });
  },[])

  return (
    <div className="App">
      <Header />
      <Rates
        leadSource="TEXAS-TV"
        phoneText="1&#8209;(855)&nbsp;ITS&#8209;QUICK"
        phoneNumber="tel:+18554877842"
      />
      <div className="formWrapper">
        {node && (
          // dynamically renders the custom form header
          <div className="formHeader">Contact Us</div>
        )}
        <Container className="formBody">
          <BorrowerLeadGenerationTool
            ref={formRef}
            leadSource="TEXAS-TV" /* Add api props here */
          />
          <a href="tel:+18554877842" className="telLink mt-4">
            1&#8209;(855)&nbsp;ITS&#8209;QUICK
          </a>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default App;
