/* eslint-disable jsx-a11y/anchor-is-valid */
/*
    6/13/2020 - Suhas
    
    This page shows the footer of the Landing page

    The footer has Legal Disclaimers.
    The images at the end are similar to SWMC.
*/

import React, { useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { GrCursor, GrFacebookOption, GrLinkedinOption, GrTwitter } from "react-icons/gr";
import twittericon from "../img/twittericon.png";
import twitterlogo from "../img/twitterlogo.png";
import TexasModal from "../includes/TexasModal";
import DisclaimerModal from "../includes/DisclaimerModal";
import Testimonials from "../components/Testimonials";

// Import images
import BBBLogo from "../img/footer/bbb.png";
import Top50 from "../img/footer/Top-50-Logo-blue.png";
import BCALogo from "../img/footer/Bcawidgets.png";
import Logo43Years from "../img/footer/43-Years-Of-Trust.png";

function Footer() {
  const [showTexasModal, setTexasModal] = useState(false);
  const [showDiscModal, setDiscModal] = useState(false);

  const handleClose = () => setTexasModal(false);
  // const handleShow = () => setTexasModal(true);
  const handleDiscClose = () => setDiscModal(false);
  const handleDiscShow = () => setDiscModal(true);

  const getSunWestSuccessYears = () => {
    // Sun West founded in 08/12/1980 in PST
    const foundedDate = new Date("1980-08-12T00:00:00-07:00"); // PST offset is -07:00
    const todayDate = new Date();

    // Adjust todayDate to PST timezone
    const todayPST = new Date(
      todayDate.toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
    );

    const yearDiff = todayPST.getFullYear() - foundedDate.getFullYear();
    const isDate2BeforeBirthday =
      todayPST.getMonth() < foundedDate.getMonth() ||
      (todayPST.getMonth() === foundedDate.getMonth() &&
        todayPST.getDate() < foundedDate.getDate());

    return isDate2BeforeBirthday ? yearDiff - 1 : yearDiff;
  };

  return (
    <>
      <Testimonials />
      <Container className="mt-3">
        <Row className="pt-4 d-flex justify-content-center text-center">
          <div className="col-xs-12 col-sm-3 col-md-3 mb-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.bbb.org/us/ca/cerritos/profile/mortgage-broker/sun-west-mortgage-company-1216-59000209"
              className="mb-3"
            >
              <img
                src={BBBLogo}
                alt="BBB accredited business"
                style={{ height: "68px" }}
              />
            </a>

            <div style={{ paddingTop: "25px" }}>
              <a
                href="https://www.checkbca.org/report/sun-west-mortgage-company-inc-59000209"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={BCALogo} alt="Bca logo" />
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-sm-3 col-md-2 mb-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://sunwestmortgage.com/"
              className="mb-3"
            >
              <img
                src={Top50}
                alt="Mortgage Executive Top 50 logo"
                title="Mortgage Executive Top 50"
              />
            </a>
          </div>
          <div className="col-xs-12 col-sm-3 col-md-2 mb-2">
            <img
              src={process.env.REACT_APP_CDN_LINK + "swmc-images/Years-Of-Trust_Blue.webp"}
              alt={`Sun West Mortgage ${getSunWestSuccessYears()} years logo`}
              title={`Sun West Mortgage ${getSunWestSuccessYears()} Years`}
              height="150"
            />
          </div>
        </Row>
      </Container>
      <Container
        className="text-center text-white mt-3"
        fluid
        style={{ backgroundColor: "#2B2B2B" }}
      >
        <Container>
          <Row className="pt-4">
            <Col>
              <a
                className="text-white"
                title="Like us on Facebook!"
                style={{ margin: "0 5px" }}
                href="https://www.facebook.com/SunWestMortgage"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GrFacebookOption style={{ fontSize: "x-large" }} />
              </a>
              &nbsp;
              <a
                className="text-white"
                title="Follow us on LinkedIn!"
                style={{ margin: "0 5px" }}
                href="https://www.linkedin.com/company/sun-west-mortgage-company/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GrLinkedinOption style={{ fontSize: "x-large" }} />
              </a>
              &nbsp;
              <a
                className="text-white"
                title="Follow us on Twitter!"
                style={{ margin: "0 5px" }}
                href="https://twitter.com/sunwestmortgage?lang=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{
                    height: '20px',
                    width: '20px',
                    fontSize: 'x-large'
                  }}
                  
                  alt="twitterlogo"
                  src={twittericon}
                  
                  onMouseOver={(e) => {
                    e.currentTarget.src = twitterlogo; // Change to twittericon on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = twittericon; // Change back to twitterlogo on mouse out
                  }}
                />

              </a>
              &nbsp;
            </Col>
          </Row>
          <Row className="pt-4">
            <Col>
              LowRates.tv (NMLS ID 3277) by Sun West Mortgage Company, Inc.
              <br />
              &copy; {new Date().getFullYear()} LowRates.tv. All Rights Reserved
              <br />
              &copy; {new Date().getFullYear()} Sun West Mortgage Company, Inc.
              All Rights Reserved
            </Col>
          </Row>
          <Row className="pt-4">
            <Col xs="1"></Col>
            <Col xs="10">
              <p>
                For licensing information, go to:{" "}
                <a
                  href="http://www.nmlsconsumeraccess.org/"
                  title="NMLS Consumer Access"
                  target="_blank"
                  rel="noreferrer"
                  className="font-weight-bold  text-white"
                >
                  www.nmlsconsumeraccess.org
                </a>
                . <br />
                Visit{" "}
                <a
                  href="https://www.swmc.com/disclaimer"
                  title="Click Here for full list of license information of Sun West Mortgage Company, Inc."
                  target="_blank"
                  rel="noreferrer"
                  className="font-weight-bold  text-white"
                >
                  www.swmc.com/disclaimer
                </a>{" "}
                for the full list of license information.
              </p>
              <p>
                Please{" "}
                <a
                  title="View Texas Complaint Notice and Servicing Disclosure"
                  href="https://www.swmc.com/TXdis.php"
                  className="text-white"
                >
                  Click Here
                </a>{" "}
                to view Texas Complaint Notice and Servicing Disclosure.
              </p>
              <p>
                <strong>
                  Although Sun West Mortgage is approved to conduct business in
                  the state of New York, this website has not yet been approved
                  by the State of New York Department of Financial Services.{" "}
                  <br />
                  For properties located in the State of New York, this website
                  cannot be used to upload an application, please visit{" "}
                  <a
                    href="https://www.swmc.com"
                    target="_blank"
                    rel="noreferrer"
                    className="text-white"
                  >
                    <u>SWMC.com</u>
                  </a>{" "}
                  to upload an application.
                </strong>
              </p>
              <p>
                <a
                  href="https://www.swmc.com/privacy-policy.php"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white"
                >
                  Privacy Policy
                </a>{" "}
                -&nbsp;
                <a
                  href="https://www.swmc.com/terms-and-conditions.php"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white"
                >
                  Terms and Conditions
                </a>{" "}
                -&nbsp;
                <a onClick={handleDiscShow} 
                  style={{cursor: 'pointer'}}
                  className="text-white text-hover-white"
                  >
                  Disclaimers
                </a>
              </p>
            </Col>
            <Col xs={12} sm={12} className="d-flex justify-content-center">
              <img
                id="equalImg"
                src="https://resources.swmc.com/swmc-images/Footer/swmc/eho_white.png"
                title="Equal Housing Opportunity"
                alt="Equal Housing Opportunity logo"
                className="mb-2"
                height="100"
              />
            </Col>
          </Row>
        </Container>
        <Modal size="lg" show={showTexasModal} onHide={handleClose} centered>
          <TexasModal />
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDiscModal} onHide={handleDiscClose} size="lg" centered>
          <DisclaimerModal />
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDiscClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}

export default Footer;
