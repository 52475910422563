import React, { useState, useEffect } from "react";
import { Modal, Table, Spinner } from "react-bootstrap";
import axios from "axios";
import parse from "html-react-parser";

function DisclaimerModal() {
  const [disclaimerData, setDisclaimerData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchDisclaimerData() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_DISCLAIMER_JSON}`);
        setDisclaimerData(response.data);
      } catch (error) {
        console.error("Error fetching disclaimer data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchDisclaimerData();
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (!disclaimerData) {
    return <p>Error loading disclaimer data.</p>;
  }

  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title className="text-white">Disclaimer</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-justify">
        <p>{disclaimerData.generalDisclaimer}</p>
        <p>
          <strong>State Licenses</strong>
        </p>
        <p style={{ textAlign: "left" }}>
          Licenses can be verified at:{" "}
          <a
            href={disclaimerData.licenseVerificationUrl}
            target="_blank"
            rel="noopener noreferrer">
            {disclaimerData.licenseVerificationUrl}
          </a>
        </p>
        <p>
          In all jurisdictions, the main licensed location of LowRates.tv is {parse(disclaimerData.mainOfficeAddress)}, Phone:{" "}
          {parse(disclaimerData.mainOfficePhone)}.
        </p>
        <Table bordered responsive>
          <thead>
            <tr>
              <th>State</th>
              <th>License Description</th>
            </tr>
          </thead>
          <tbody>
            {disclaimerData.licenses.map((license, index) => (
              <tr key={index}>
                <td style={{ verticalAlign: "middle", textAlign: "center" }}>{parse(license.state)}</td>
                <td>{parse(license.description)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </React.Fragment>
  );
}

export default DisclaimerModal;
